export const environment = {
  production: true,
  ptServiceUrl: 'https://pt-legacy-service.rtspro.com/',
  pcMilerAPIKey: '342A219D10D4414E9023E81B2589A754',
  firebase: {
    databaseURL: 'https://protransport.firebaseio.com',
    apiKey: 'AIzaSyAwRcGXEMJ6iEONTGANSO7DNCeZgbF8vDo',
    projectId: 'carrierpro-144918',
  },
  firebaseAnalytics: {
    apiKey: 'AIzaSyChX6_BJyEiQyebnwMQbYhurIUPRoZLnLE',
    authDomain: 'carrierpro-144918.firebaseapp.com',
    databaseURL: 'https://carrierpro-144918.firebaseio.com',
    projectId: 'carrierpro-144918',
    storageBucket: 'carrierpro-144918.appspot.com',
    messagingSenderId: '715693729194',
    appId: '1:715693729194:web:9ddbb35da0c9750d67b7d3',
    measurementId: 'G-Y1EFMEJ7NL',
  },
};
